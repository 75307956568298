import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { GiHamburgerMenu } from 'react-icons/gi';
import { EXTENSION_URL } from "../../constants";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

export default function TemporaryDrawer() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (side, open) => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [side]: open });
  };

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(side, false)}
      onKeyDown={toggleDrawer(side, false)}
    >
      <List>
        <ListItem button component="a" href="/Home" key="Home">
          <ListItemIcon></ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>

        <ListItem button component="a" href="/About-us" key="AboutUs">
          <ListItemIcon></ListItemIcon>
          <ListItemText primary="About Us" />
        </ListItem>

        <ListItem button component="a" href="/FAQ" key="FAQ">
          <ListItemIcon></ListItemIcon>
          <ListItemText primary="F.A.Q" />
        </ListItem>
        <Divider />
        <ListItem button component="a" href={EXTENSION_URL} key="Download">
          <ListItemIcon></ListItemIcon>
          <ListItemText primary="Download" />
        </ListItem>

      </List>
    </div>
  );


  return (
    <div>
      <Button
        style={{right: '0'}}
        onClick={toggleDrawer('right', true)}><GiHamburgerMenu fontSize="2rem" /></Button>

      <Drawer anchor="right" open={state.right} onClose={toggleDrawer('right', false)}>
        {sideList('right')}
      </Drawer>
    </div>
  );
}