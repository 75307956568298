import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import { BACKGROUND_COLOR } from '../styles';

import 'bootstrap/dist/css/bootstrap.min.css';



function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center" style={{paddingBottom: '1rem'}}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.valugenie.com/">
        Valu-Genie
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Footer = (props) => {
    return (
        <Box style={{backgroundColor: BACKGROUND_COLOR, paddingTop: '3rem'}} >
            <div align="center">
                <a href="/Terms">Terms</a> and <a href="/Privacy-Policy">Privacy Policy</a>
            </div>
            <Copyright />
         </Box>
    )
}

export default Footer;