import ReactGA from 'react-ga';
//import auth from './auth.ts'; // Sample authentication provider


const initializeReactGA = () => {
    const trackingId = "UA-152790255-1"; // Replace with your Google Analytics tracking ID
    ReactGA.initialize(trackingId);
}

/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
export const Event = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label
  });
};

export default initializeReactGA;

/*
ReactGA.set({
  userId: auth.currentUserId(),
  // any data that is relevant to the user session
  // that you would like to track with google analytics
})
*/
/*
ReactGA.event({
  category: "Sign Up",
  action: "User pressed the big blue sign up button",
});
*/