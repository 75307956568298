import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import TemporaryDrawer from './DrawerMenu';

import logoSvg from '../../images/Icon_128x128.png';


const HeaderNavbar = (props) => {

  // TODO: If window size is too small, change to hamburger menu
  return (

      <Navbar variant="light" style={{backgroundColor: "#add6ff"}}>
        <Navbar.Brand href="/Home" className="mr-auto">
            <img src={logoSvg} alt="" style={{height: '3rem', width: '3rem', marginLeft: '1rem', marginRight: '0.5rem', marginTop: '-0.5rem'}} />
            <div style={{display:'inline-block'}}>
                Valu Genie
            </div>
        </Navbar.Brand>
        <TemporaryDrawer />
      </Navbar>


  )
}

export default HeaderNavbar;