import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';


const Answer = (props) => {
    return (
        <div>
            <body style={{paddingLeft: "2rem"}}>
                {props.text}
            </body>
        </div>
    )
}

export default Answer;