import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import Answer from "./Answer";

import 'bootstrap/dist/css/bootstrap.min.css';


const Question = (props) => {
    return (
        <Accordion style={{margin: 'auto', marginLeft: "2rem", padding: "0.5rem", paddingTop: "1rem"}}>
            <Accordion.Toggle as={Card.Header} eventKey="0" >
                <h5>+ {props.question}</h5>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
                <Answer text={props.answer} />
            </Accordion.Collapse>
        </Accordion>
    )
}

export default Question;