import React from 'react';
import CardDeck from 'react-bootstrap/CardDeck';
import Card from 'react-bootstrap/Card';

import barChartEmoji from '../images/emojies/bar-chart_1f4ca.png';
import moneyBagEmoji from '../images/emojies/money-bag_1f4b0.png';
import searchGlassEmoji from '../images/emojies/magnifying-glass_dollar.png';

const HowItWorksBanner = (props) => {

    return (
        <CardDeck>
          <Card >
            <Card.Img variant="top" src={searchGlassEmoji} style={{marginTop: '1rem', width: '5rem', height: '5rem'}} />
            <Card.Body>
              <Card.Title>Search</Card.Title>
              <Card.Text>
                Search for the specific types of products you need for the investment level you want.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img variant="top" src={barChartEmoji} style={{marginTop: '1rem', width: '5rem', height: '5rem'}} />
            <Card.Body>
              <Card.Title>Compare</Card.Title>
              <Card.Text>
                Compare your choice against the top contenders for alternatives.
              </Card.Text>
            </Card.Body>
          </Card>
          <Card>
            <Card.Img variant="top" src={moneyBagEmoji} style={{marginTop: '1rem', width: '5rem', height: '5rem'}} />
            <Card.Body>
              <Card.Title>Relax</Card.Title>
              <Card.Text>
                Take comfort in knowing you are getting the best investment for the product you need.
              </Card.Text>
            </Card.Body>
          </Card>
        </CardDeck>
        )
    }

export default HowItWorksBanner;
