import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ReactGA from 'react-ga';

import HomePage from './HomePage';
import FAQ from './modules/faq/Faq';
import AboutUs from './modules/about-us/AboutUs';
import TermsAndConditions from './modules/terms/TermsAndConditions';
import PrivacyPolicy from './modules/privacy-policy/PrivacyPolicy';
import Header from './modules/header/Header';
import Footer from './modules/footer/Footer';

import { BACKGROUND_COLOR } from './modules/styles'

import initializeReactGA from './google-analytics/analytics';

initializeReactGA();

function trackHistory() {
  ReactGA.set({ page: 'dev-' + window.location.pathname }); // Update the user's current page
  ReactGA.pageview('dev-' + window.location.pathname); // Record a pageview for the given page
}

export default () => {
        trackHistory();
        return(
            <BrowserRouter style={{backgroundColor: BACKGROUND_COLOR}}>
                <Header />
                <Switch>
                    <Route exact path="/" component={HomePage}/>
                    <Route exact path="/Home" component={HomePage}/>
                    <Route exact path="/FAQ" component={FAQ}/>
                    <Route exact path="/About-us" component={AboutUs}/>
                    <Route exact path="/Terms" component={TermsAndConditions}/>
                    <Route exact path="/Privacy-Policy" component={PrivacyPolicy}/>
                    <Route component={HomePage}/>
                </Switch>
                <Footer />
            </BrowserRouter >
            )
};