import React from 'react';
import './App.css';
import Button from 'react-bootstrap/Button';
import Box from '@material-ui/core/Box';
import YouTube from 'react-youtube';
import Typography from '@material-ui/core/Typography';
import { EXTENSION_URL } from "./constants";
import { BANNER_BACKGROUND_COLOR, BACKGROUND_COLOR } from './modules/styles'

import LazyLoad from 'react-lazy-load';

import HowItWorksBanner from './modules/HowItWorksBanner'
import howItWorksGraph from './images/how_it_works_graph.gif';

import 'bootstrap/dist/css/bootstrap.min.css';


class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
            screenWidth: window.innerWidth,
            };
  }

//  componentWillMount() {
//      window.addEventListener('resize', this.handleWindowSizeChange);
//  }

  componentDidMount() {
      window.addEventListener('resize', this.handleWindowSizeChange);
  }

    // make sure to remove the listener
    // when the component is not mounted anymore
  componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
      this.setState({ screenWidth: window.innerWidth });
  };

  _onReady(event) {
      // access to player in all event handlers via event.target
      event.target.pauseVideo();
  }

  render() {
      var videoWidth = 480;
      var videoHeight = videoWidth * (3/4.8);
      var gifWidth = '30.08rem';
      var gifHeight = '18.08rem';

      if (window.innerWidth < 480){
          // Need to adjust video and GIF size dependent on screen width
          // Screen width is mobile size
          videoWidth = window.innerWidth * 0.8;
          videoHeight = videoWidth * (3/4.8);
          gifWidth = window.innerWidth * 0.8;
          gifHeight = gifWidth * (18.08/30.08);
      }

      const videoOpts = {
          height: videoHeight,
          width: videoWidth,
          playerVars: { // https://developers.google.com/youtube/player_parameters
            autoplay: 0
          }
        };

      return (
            <div>
                <title>Valu Genie will find you the best computer product to invest in!</title>
                <meta name="Description"  CONTENT="Stop wasting time and money searching everywhere for the best computer products. Valu Genie will find you them instantly for free!" />

                <Box shadow={0} bgcolor={BANNER_BACKGROUND_COLOR} style={{textAlign: 'center', width: '100%'}}>
                    <div style={{padding: '2rem'}}>
                        <Typography style={{textAlign: 'center', marginBottom: '1.5rem'}} variant="h3" >
                            Search, Compare, Relax.
                        </Typography>
                        <Typography align="center" style={{width: '80%', margin: 'auto', textAlign: 'center', marginBottom: '2rem'}} variant="body1" >
                            Stop wasting time and money - Valu Genie will find you the best computer product to invest in!
                        </Typography>

                        <LazyLoad
                          width='100%'
                          height={videoHeight}
                          debounce={false}
                          >
                            <YouTube
                                    videoId="PjhmLLs4mnc"
                                    opts={videoOpts}
                                    onReady={this._onReady}
                                    style
                                  />
                        </LazyLoad>

                        <div style={{textAlign: 'center', marginTop: '2.5rem'}} >
                            <Button variant="primary" href={EXTENSION_URL} style={{padding: '1rem'}}>
                                 Add Valu Genie to Chrome - For Free
                            </Button>
                        </div>
                    </div>

                </Box>

                <Box bgcolor={BACKGROUND_COLOR} align="center">
                    <div align="center" style={{width: "90%"}}>

                        <Typography align="center" style={{textAlign: 'center', paddingTop: '2rem'}} variant="h4" >
                            How does it work?
                        </Typography>

                        <div style={{marginTop: '2rem', marginBottom: '2rem'}}>
                            <HowItWorksBanner />
                        </div>

                        <Typography align="center" style={{width: '80%', margin: 'auto', textAlign: 'center', marginBottom: '2.5rem', marginTop: '1.5rem'}} variant="body1" >
                            Valu Genie allows you to search for the specific types of products you need for the investment level you want.
                        </Typography>

                        <LazyLoad
                          width={gifWidth}
                          height={gifHeight}
                          debounce={false}
                          offsetVertical={300}
                          >
                            <img src={howItWorksGraph} alt="" style={{width: gifWidth, height: gifHeight}} />
                        </LazyLoad>

                        <Typography align="center" style={{width: '80%', margin: 'auto', textAlign: 'center', marginBottom: '2.5rem', marginTop: '1.5rem'}} variant="body1" >
                        For example, if you want to spend $175 on a processor, then Valu Genie can find you the best processor in your price range. If you already have a specific product in mind, or would just like to know other options, Valu Genie's Comparison technology will find the 3 next best choices to choose from, and details explaining their performance. You can't go wrong when you use valu genie to help you find your best computer investment.
                        </Typography>


                        <Typography align="center" style={{width: '80%', margin: 'auto', textAlign: 'center', paddingBottom: '0.5rem', marginTop: '3rem'}} variant="body1" >
                            Looking to find out more? Check out the extension <a href={EXTENSION_URL}>here</a>. Or visit our <a href="/About-us">About Us</a> and <a href="/FAQ">Frequently Asked Questions</a> pages.
                        </Typography>

                    </div>
                </Box>

        </div>
          );
      }

}

export default HomePage;
