import React from 'react';
import Question from "./modules/Question";
import { EXTENSION_URL } from "../../constants";

import { BACKGROUND_COLOR } from '../styles';

import 'bootstrap/dist/css/bootstrap.min.css';


const FAQ = (props) => {
    return (
        <div style={{backgroundColor: BACKGROUND_COLOR }} >
            <div align="center" style={{paddingTop: '2rem', paddingBottom: "1rem"}}>
                <h1>Frequently Asked Questions</h1>
            </div>
            <div style={{margin: "auto", width: "80%"}}>
                <Question
                    question="How do I use Valu genie?"
                    answer={<div>The Valu Genie chrome extension can be added by visiting <a href={EXTENSION_URL}>our extension page here</a>. There are two primary features to use: the search and the compare. The search function allows you to specify your requirements and price, and Valu Genie will find you the best product to your specifications. The compare function will compare the current product page you viewed, and see what the best options available would be for that given product.</div>}
                />

                <Question
                    question="Is Valu Genie Free?"
                    answer={<div>Absolutely! We make commission when you buy the product using our affiliate links. So, you save money <b>and</b> get the best product, the vendor makes a sale, and we make a commission from their sales.</div>}
                />

                <Question
                    question="Does Valu Genie use coupons?"
                    answer="No, Valu Genie is not a coupon finding service. We're here to compare products, and find you the best product on the market for your given price range."
                />

                <Question
                    question="Does Valu Genie really find me the best price?"
                    answer="Yes! We evaluate the product page you are on, search through many of our popular and trusted vendors for a similar or better product for the best price."
                />

                <Question
                    question="I have something I want to say, who can I talk to?"
                    answer={<div>Please let us know if you have any questions or concerns at <a href="mailto:valugenie@gmail.com">support@valugenie.com</a> and we will get back to you as soon as possible. We always welcome feedback!</div>}
                />
            </div>
        </div>
    )
}

export default FAQ;