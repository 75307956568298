import React from 'react';

//import logoSvg from '../../images/FullColor_1024x1024_72dpi.png';
import HeaderNavbar from './HeaderNavbar';

import 'bootstrap/dist/css/bootstrap.min.css';


const Header = (props) => {
    return (
        <div>
            <HeaderNavbar />
            <div style={{width: '100%', textAlign:'center'}}>
                {/*<img src={logoSvg} alt="" style={{height: '10rem', width: '10rem'}} />*/}
                <div/>
            </div>
        </div>
    )
}

export default Header;