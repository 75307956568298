import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from 'react-bootstrap/Button';
import { EXTENSION_URL } from "../../constants";

import { BACKGROUND_COLOR } from '../styles'

import 'bootstrap/dist/css/bootstrap.min.css';


const AboutUs = (props) => {
    return (
        <div style={{backgroundColor: BACKGROUND_COLOR, height: '100%'}}>
            <div style={{align: "center", marginLeft: "auto", marginRight: 'auto', width: "80%"}}>
                <Typography variant="h4" align="center" style={{paddingTop:'2rem', paddingBottom: '1rem'}}>Our Service</Typography>

                <Typography variant="body1" align="center" style={{margin: 'auto', width: '80%'}}>
                    Using artificial intelligence and value metrics, Valu Genie searches through products to find you the best product on the market for the best price.
                </Typography>

                <h2 style={{textAlign: 'center', marginTop: '2.5rem'}}>Our Story</h2>
                <Typography variant="body1" align="center">
                    Valu Genie is inspired by a very frugal computer engineer, who was tired of constantly being asked by family and friends about what computer parts are the best investment. We want to reduce the number of hours spent tirelessly wasting time when looking for a product. With Valu Genie, you will no longer need to ask others what would be the best [computer] product to buy, or spend hours searching it yourself! We do all the hard work for you. If something is not working right, please email us at support@valugenie.com so we can fix it right away!
                </Typography>

                <div style={{textAlign: 'center', marginTop: '2rem'}} >
                    <Button variant="primary" href={EXTENSION_URL} style={{padding: '0.5rem'}}>
                         Try Valu Genie - For Free
                    </Button>
                </div>

            </div>
        </div>
    )
}

export default AboutUs;